<template>
  <div class="experience">
      <table class="experiencetable">
    <h2 class="experience__title">EXPERIENCE</h2>
        <tr class="experiencetable__row1">
          <td class="experiencetable__row1--item1">CC PROJECTS</td>
          <td class="experiencetable__row1--item2">AUG 2021 - OCT 2021</td>
          <td class="experiencetable__row1--item3">CREATIVE DEVELOPER INTERN</td>
        </tr>
        <tr class="experiencetable__row2">
          <td class="experiencetable__row2--item1">IT-HÖGSKOLAN</td>
          <td class="experiencetable__row2--item2">2020 - 2022</td>
          <td class="experiencetable__row2--item3">JAVASCRIPT DEVELOPER</td>
        </tr>
      </table>
  </div>
</template>

<script>
import './ExperienceTable.scss'

export default {
  name: 'ExperienceTable'
}
</script>
