<template>
  <div class="otherprojects">
    <h2 class="otherprojects__title">OTHER PROJECTS</h2>
    <div class="otherprojects__container">
      <img
        class="container__row1 container__row1--item1"
        src="@/assets/images/comingsoon.png"
        alt="coming soon"
      />
       <img
        class="container__row1 container__row1--item2"
        src="@/assets/images/comingsoon.png"
        alt="coming soon"
      />
       <img
        class="container__row1 container__row1--item3"
        src="@/assets/images/comingsoon.png"
        alt="coming soon"
      />
       <img
        class="container__row2 container__row2--item1"
        src="@/assets/images/comingsoon.png"
        alt="coming soon"
      />
       <img
        class="container__row2 container__row2--item2"
        src="@/assets/images/comingsoon.png"
        alt="coming soon"
      />
       <img
        class="container__row2 container__row2--item3"
        src="@/assets/images/comingsoon.png"
        alt="coming soon"
      />
    </div>
  </div>
</template>

<script>
import './OtherProjectGrid.scss'

export default {
  name: 'OtherProjectGrid'
}
</script>
